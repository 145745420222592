import gsap from "gsap";
import { ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin);

import MicroModal from 'micromodal';


console.clear();

document.addEventListener("DOMContentLoaded", function() {
  try {
	 MicroModal.init({
    // onShow: modal => console.info(`${modal.id} is shown`), // [1]
    // onClose: modal => console.info(`${modal.id} is hidden`), // [2]
    onClose: onClose_f,
    openTrigger: 'data-custom-open', // [3]
    // closeTrigger: 'data-custom-close', // [4]
    // openClass: 'is-open', // [5]
    // disableScroll: false, // [6]
    // disableFocus: false, // [7]
    // awaitOpenAnimation: false, // [8]
    // awaitCloseAnimation: true, // [9]
    // debugMode: true // [10]
	 });
	} catch (e) {
    console.log("micromodal error: ", e);
  }

  cardhovereffect();


// VIDEO TEASER
  let player = document.getElementById("teaser"),
      play = document.getElementById("play-teaser");
  play.addEventListener("click",function(){
    player.play();
  });

  function onClose_f() {
    player.pause();
  }

});

// gsap.defaultEase = Linear.easeNone;
gsap.set(".agenda-dates, .agenda-article-block", {autoAlpha:1});
var targets = document.querySelectorAll(".agenda-dates li");
var articles = document.querySelectorAll(".article");

var activeTab = 0;
var old = 0;
var heights = [];
var widths = [];
var dur = 0.6;
var animation;
var loopStart = 0;
var loopEnd = 0;

// const activeColor = '#bd9f6c'
const activeColor = '#666666'

function doCoolStuff() {
  // check if clicked target is new and if the timeline is currently active
  if(this.index != activeTab) {
    //if there's an animation in-progress, jump to the end immediately so there aren't weird overlaps.
    if (animation && animation.isActive()) {
      animation.progress(1);
    }
    animation = new gsap.timeline();
    old = activeTab;
    activeTab = this.index;
    var stretch = 0;

    if (activeTab > old) {
      loopStart = old;
      loopEnd = activeTab;

    } else {
      loopStart = activeTab;
      loopEnd = old;

      // if moving slider bubble right to left, also animate new x position while stretching
      animation.to(".agenda-slider", dur, {x:targets[activeTab].offsetLeft}, 0);
    }
    // get total width of all tabs between new and old (inclusive)
     for (let i = loopStart; i < loopEnd + 1; i++) {
      stretch += widths[i];
    }
    // stretch the slider bubble to the start of the new target
    animation.to(".agenda-slider", dur, {width:stretch}, 0);

    // animate bubble slider to clicked target
    animation.to(".agenda-slider", dur, {x:targets[activeTab].offsetLeft, width:widths[activeTab]}, "springBack");
    // change text color on old and new tab targets
    animation.to(targets[old], dur, {color:activeColor}, "springBack");
    animation.to(targets[activeTab], dur, {color:"#fff"}, "springBack");
    // slide current article down out of view and then set it to starting position at top
    animation.to(articles[old], dur, {y:heights[old]}, "springBack");
    animation.set(articles[old], {y:-heights[old]});
    // resize article block to accommodate new content
    animation.to(".agenda-article-block", dur, {height:heights[activeTab]});
    // slide in new article
    animation.to(articles[activeTab], 1, {y:0}, "-=0.9");
  }
}

for (let i = 0; i < targets.length; i++) {
  targets[i].index = i;
  heights.push(articles[i].offsetHeight); // get height of each article
  widths.push(targets[i].offsetWidth); // get width of each tab
  gsap.set(articles[i], {top: 0, y:-heights[i]}); // push all articles up out of view
  targets[i].addEventListener("click", doCoolStuff);
}

// set initial article and position stretchy bubble slider on first tab
gsap.set(articles[0], {y:0});
gsap.set(".agenda-slider", {x:targets[0].offsetLeft, width:targets[0].offsetWidth});
gsap.set(targets[0], {color:"#fff"});
gsap.set(".agenda-article-block", {height:heights[0]});

gsap.set("line", {drawSVG:0});


// SCROLL TO ANCHOR

function getSamePageAnchor (link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false;
  }
  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if(elem) {
    if(e) e.preventDefault();
    gsap.to(window, {
      duration: 0.75,
      scrollTo: elem
    });
  }
}

// If a link's href is within the current page, scroll to it instead
document.querySelectorAll('a[href]').forEach(a => {
  a.addEventListener('click', e => {
    scrollToHash(getSamePageAnchor(a), e);
  });
});

// Scroll to the element in the URL's hash on load
scrollToHash(window.location.hash);




function cardhovereffect() {
  gsap.utils.toArray(".speaker--card").forEach(card => {

    let q = gsap.utils.selector(card);

    gsap.set(q('.card-info-wrapper'), {
      yPercent: 100, //prima 80
      y: 0
    });

    let animation = gsap.timeline();

    card.addEventListener("mouseover", () => {
      // tl.play()
      animation.kill();
      animation = gsap.timeline()
        .to(q('.card-info-wrapper'), { duration: .3, yPercent: 0 })
        .to(q('.card-info'), { duration: .1, top: '50%', yPercent: -50}, '-=.3')
        .to(q('.card-title'), { duration: .2, opacity: 1 }, '-=.15')
        .to(q('.card-desc'), { duration: .3, opacity: 1 }, '-=.15')
        .to(q('.card-action'), { duration: .3, opacity: 1 }, '-=.3');
    });
    card.addEventListener("mouseleave", () => {
      // tlr.play()
      animation.kill();
      animation = gsap.timeline()
        .to(q('.card-action'), { duration: .1, opacity: 0 })
        .to(q('.card-title'), { duration: .1, opacity: 0}, '>-=.1')
        .to(q('.card-desc'), { duration: .1, opacity: 0}, '>-=.1')
        .to(q('.card-info-wrapper'), { duration: .3, yPercent: 100 })
        .set(q('.card-info'), { top: 0, yPercent: 0 }, '>-=.3');
    });
  });
}



// COPY TO CLIPBOARD

let selectable = document.querySelector('[data-ctc]');

// deselect on mouseleave
selectable.addEventListener('mouseleave',()=>{
  document.getSelection().removeAllRanges()
});


selectable.addEventListener('click', ctc)
// selectable.addEventListener('mouseover', ctc)

// select the element and copy
function ctc(e){
let range = document.createRange();
  range.selectNode(this);
  window.getSelection().addRange(range);
 if(e.type == "click") {
   document.execCommand("copy"); // native copy function
   flash();
 }
}

// simple flash message
function flash() {
  let tooltip = document.querySelector(".add-copied");
  tooltip.classList.remove("hidden");
  setTimeout(() => {
    tooltip.classList.add("hidden");
  }, 4000);
}
